<template>
  <v-form ref="formExchanges" v-model="formExchangesValid">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left">Inversión real MI (sin comisión)</th>
            <th class="text-left">TC</th>
            <th class="text-left">ML ({{ projectCurrency }})</th>
          </tr>
        </thead>
        <!-- ----------------------------- RETAIL transfers ----------------------------- -->
        <tbody v-if="project.transfers.some((e) => e.investor_type === 'RETAIL')">
          <tr class="black">
            <td colspan="4" class="white--text text-center">RETAIL</td>
          </tr>
        </tbody>
        <tbody v-for="favoriteCurrency in favoriteCurrencies" :key="makeid(favoriteCurrency.length)">
          <tr v-if="totalInvestedByCurrency(favoriteCurrency, 'RETAIL') > 0" class="blue lighten-4">
            <td>{{ favoriteCurrency }} DN</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr
            v-for="transfer in project.transfers.filter(
              (e) => e.from === favoriteCurrency && e.investor_type === 'RETAIL' && !e.is_reinvested
            )"
            :key="transfer.investment_id"
          >
            <td>- {{ transfer.investor_first_name }} {{ transfer.investor_last_name }}</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
            <td>
              <v-text-field
                v-if="isEditable"
                v-model.number="transfer.exchange_rate"
                type="number"
                filled
                dense
                class="mt-2 mb-n4"
                :readonly="transfer.from === transfer.to"
                @input="$emit('change'), setAmountConverted(transfer)"
              >
                <template slot="append">
                  <v-tooltip v-if="controlExchangeRate(transfer)" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="warning">info</v-icon>
                    </template>
                    <span>Variación mayor a 10%</span>
                  </v-tooltip>
                </template>
                <template slot="append-outer">
                  <v-btn small icon class="mb-2" @click="$emit('change'), resetExchangeRate(transfer)">
                    <v-icon small>restart_alt</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <span v-else>{{ transfer.exchange_rate }}</span>
            </td>
            <td>{{ transfer.amount_converted }}</td>
          </tr>
        </tbody>
        <!-- REINVESTMENTS EXCHANGE RATE VARIABLE -->
        <tbody v-for="favoriteCurrency in favoriteCurrencies" :key="makeid(favoriteCurrency.length)">
          <tr v-if="totalInvestedByCurrency(favoriteCurrency, 'RETAIL', false, true) > 0" class="blue lighten-4">
            <td>Reinversiones variables</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr
            v-for="transfer in project.transfers.filter(
              (e) => e.from === favoriteCurrency && e.investor_type === 'RETAIL' && e.is_reinvested && e.exchange_rate !== 1
            )"
            :key="transfer.investment_id"
          >
            <td>- {{ transfer.investor_first_name }} {{ transfer.investor_last_name }}</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
            <td>
              <v-text-field
                v-if="isEditable"
                v-model.number="transfer.exchange_rate"
                type="number"
                filled
                dense
                class="mt-2 mb-n4"
                :readonly="transfer.from === transfer.to"
                @input="$emit('change'), setAmountConverted(transfer)"
              >
                <template slot="append">
                  <v-tooltip v-if="controlExchangeRate(transfer)" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="warning">info</v-icon>
                    </template>
                    <span>Variación mayor a 10%</span>
                  </v-tooltip>
                </template>
                <template slot="append-outer">
                  <v-btn small icon class="mb-2" @click="$emit('change'), resetExchangeRate(transfer)">
                    <v-icon small>restart_alt</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <span v-else>{{ transfer.exchange_rate }}</span>
            </td>
            <td>{{ transfer.amount_converted }}</td>
          </tr>
        </tbody>
        <!-- REINVESTMENTS EXCHANGE RATE FIXED TO 1 -->
        <tbody>
          <tr
            v-if="project.transfers.some((e) => e.investor_type === 'RETAIL' && e.is_reinvested && e.exchange_rate === 1)"
            class="blue lighten-4"
          >
            <td>Reinversiones fijas</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr
            v-for="transfer in project.transfers.filter((e) => e.investor_type === 'RETAIL' && e.is_reinvested && e.exchange_rate === 1)"
            :key="transfer.investment_id"
          >
            <td>- {{ transfer.investor_first_name }} {{ transfer.investor_last_name }}</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
            <td>1</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
          </tr>
        </tbody>

        <!-- ----------------------------- FUND transfers ----------------------------- -->
        <tbody v-if="project.transfers.some((e) => e.investor_type === 'FUND')">
          <tr class="black">
            <td colspan="4" class="white--text text-center">SPV</td>
          </tr>
        </tbody>
        <tbody v-for="(favoriteCurrency, i) in favoriteCurrencies" :key="i">
          <tr v-if="totalInvestedByCurrency(favoriteCurrency, 'FUND') > 0" class="blue lighten-4">
            <td>{{ favoriteCurrency }} DN</td>
            <td>{{ totalInvestedByCurrency(favoriteCurrency, "FUND") }}</td>
            <td></td>
            <td>{{ totalInvestedByCurrency(favoriteCurrency, "FUND", true) }}</td>
          </tr>
          <tr
            v-for="transfer in project.transfers.filter(
              (e) => e.from === favoriteCurrency && e.investor_type === 'FUND' && !e.is_reinvested
            )"
            :key="transfer.investment_id"
          >
            <td>- {{ transfer.investor_first_name }} {{ transfer.investor_last_name }}</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
            <td>
              <v-text-field
                v-if="isEditable"
                v-model.number="transfer.exchange_rate"
                type="number"
                filled
                dense
                class="mt-2 mb-n4"
                :readonly="transfer.from === transfer.to"
                @input="$emit('change'), setAmountConverted(transfer)"
              >
                <template slot="append">
                  <v-tooltip v-if="controlExchangeRate(transfer)" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="warning">info</v-icon>
                    </template>
                    <span>Variación mayor a 10%</span>
                  </v-tooltip>
                </template>
                <template slot="append-outer">
                  <v-btn small icon class="mb-2" @click="$emit('change'), resetExchangeRate(transfer)">
                    <v-icon small>restart_alt</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <span v-else>{{ transfer.exchange_rate }}</span>
            </td>
            <td>{{ transfer.amount_converted }}</td>
          </tr>
        </tbody>
        <!-- REINVESTMENTS EXCHANGE RATE VARIABLE -->
        <tbody v-for="favoriteCurrency in favoriteCurrencies" :key="makeid(favoriteCurrency.length)">
          <tr v-if="totalInvestedByCurrency(favoriteCurrency, 'FUND', false, true) > 0" class="blue lighten-4">
            <td>Reinversiones variables</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr
            v-for="transfer in project.transfers.filter(
              (e) => e.from === favoriteCurrency && e.investor_type === 'FUND' && e.is_reinvested && e.exchange_rate !== 1
            )"
            :key="transfer.investment_id"
          >
            <td>- {{ transfer.investor_first_name }} {{ transfer.investor_last_name }}</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
            <td>
              <v-text-field
                v-if="isEditable"
                v-model.number="transfer.exchange_rate"
                type="number"
                filled
                dense
                class="mt-2 mb-n4"
                :readonly="transfer.from === transfer.to"
                @input="$emit('change'), setAmountConverted(transfer)"
              >
                <template slot="append">
                  <v-tooltip v-if="controlExchangeRate(transfer)" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="warning">info</v-icon>
                    </template>
                    <span>Variación mayor a 10%</span>
                  </v-tooltip>
                </template>
                <template slot="append-outer">
                  <v-btn small icon class="mb-2" @click="$emit('change'), resetExchangeRate(transfer)">
                    <v-icon small>restart_alt</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <span v-else>{{ transfer.exchange_rate }}</span>
            </td>
            <td>{{ transfer.amount_converted }}</td>
          </tr>
        </tbody>
        <!-- REINVESTMENTS EXCHANGE RATE FIXED TO 1 -->
        <tbody>
          <tr
            v-if="project.transfers.some((e) => e.investor_type === 'FUND' && e.is_reinvested && e.exchange_rate === 1)"
            class="blue lighten-4"
          >
            <td>Reinversiones fijas</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr
            v-for="transfer in project.transfers.filter((e) => e.investor_type === 'FUND' && e.is_reinvested && e.exchange_rate === 1)"
            :key="transfer.investment_id"
          >
            <td>- {{ transfer.investor_first_name }} {{ transfer.investor_last_name }}</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
            <td>1</td>
            <td>{{ currency(transfer.amount, { fromCents: true }) }}</td>
          </tr>
        </tbody>

        <!-- Summary all transfers -->
        <tbody>
          <tr class="orange lighten-4">
            <td>Suma DN</td>
            <td></td>
            <td></td>
            <td>{{ totalNotReinvested }}</td>
          </tr>
          <tr class="orange lighten-4">
            <td>Suma DR</td>
            <td></td>
            <td></td>
            <td>{{ totalReinvested }}</td>
          </tr>
          <tr class="orange lighten-4">
            <td>Total (DN + DR)</td>
            <td></td>
            <td></td>
            <td>{{ currency(totalNotReinvested).add(totalReinvested) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import _ from "lodash";
import * as currency from "currency.js";

export default {
  name: "ProjectSummaryTable",
  mixins: [CommonMixin],
  props: {
    project: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      formExchangesValid: false,
      componentKey: 0
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.currencies.currencies
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },

    // Return the key of the project currency
    projectCurrency() {
      return this.project.currency;
    },

    // Return an array of favorites currencies
    favoriteCurrencies() {
      const favoriteCurrencies = [];
      for (const currency of this.currencies) {
        if (currency.investable) favoriteCurrencies.push(currency.id);
      }
      return favoriteCurrencies;
    },

    // Return total amount not reinvested
    totalNotReinvested() {
      let total = 0;
      for (const transfer of this.project.transfers) {
        if (!transfer.is_reinvested) {
          total = currency(total).add(currency(transfer.amount, { fromCents: true }).multiply(transfer.exchange_rate));
        }
      }

      return currency(total);
    },

    // Return total amount reinvested
    totalReinvested() {
      let total = 0;
      for (const transfer of this.project.transfers) {
        if (transfer.is_reinvested) {
          total = currency(total).add(currency(transfer.amount, { fromCents: true }).multiply(transfer.exchange_rate));
        }
      }

      return currency(total);
    }
  },
  async created() {
    for (const transfer of this.project.transfers) {
      const { investor_first_name, investor_last_name, investor_type } = await this.getAdditionalInfoFromInvestor(transfer.investor_id);
      this.$set(transfer, "amount_converted", currency(transfer.amount, { fromCents: true }).multiply(transfer.exchange_rate));
      this.$set(transfer, "investor_first_name", investor_first_name);
      this.$set(transfer, "investor_last_name", investor_last_name);
      this.$set(transfer, "investor_type", investor_type);
    }
  },
  methods: {
    // Return total amount invested in specific currency and with or without exchange rate
    totalInvestedByCurrency(currencyId, investorType = "ALL", withExchangeRate = false, isReinvested = false) {
      let total = 0;
      for (const transfer of this.project.transfers.filter(function (e) {
        if (investorType !== "ALL") {
          return e.from === currencyId && e.investor_type === investorType && e.is_reinvested === isReinvested;
        } else return e.from === currencyId;
      })) {
        if (withExchangeRate) {
          total = currency(total).add(currency(transfer.amount, { fromCents: true }).multiply(transfer.exchange_rate));
        } else total = currency(total).add(currency(transfer.amount, { fromCents: true }));
      }

      return currency(total);
    },

    // Set the exchange rate by default
    async resetExchangeRate(transfer) {
      if (transfer.investor_type === "FUND") {
        const { exchange_rates } = await this.$store.dispatch("investors/getInvestor", { filter: { id: transfer.investor_id } });
        const project = await this.$store.dispatch("projects/getProject", { filter: { id: this.project.project_id } });
        transfer.exchange_rate = exchange_rates[project.partner.id];
      } else {
        const defaultExchangeRate = this.getDefaultExchangeRate(transfer.from, transfer.to);
        transfer.exchange_rate = defaultExchangeRate;
      }
      this.setAmountConverted(transfer);
    },

    // check that the exchange rate does not exceed the percentage
    controlExchangeRate(transfer) {
      const defaultExchangeRate = this.getDefaultExchangeRate(transfer.from, transfer.to);
      const percentage = 10;
      const maxExchangeRate = (defaultExchangeRate * (100 + percentage)) / 100;
      const minExchangeRate = (defaultExchangeRate * (100 - percentage)) / 100;

      return transfer.exchange_rate <= minExchangeRate || transfer.exchange_rate >= maxExchangeRate;
    },

    async getAdditionalInfoFromInvestor(investorId) {
      const investor = await this.$store.dispatch("investors/getInvestor", { filter: { id: investorId } });
      return { investor_first_name: investor.first_name, investor_last_name: investor.last_name, investor_type: investor.type };
    },

    // Set amount converted of each transfer
    setAmountConverted(transfer) {
      transfer.amount_converted = currency(transfer.amount, { fromCents: true }).multiply(transfer.exchange_rate);
    }
  }
};
</script>
